$(document).ready(function() {
  $('.popup-trigger').click(function() {
    $('.popup-container').toggleClass('show');
  });

  $('.popup-container').click(function(event) {
    if ($(event.target).is('.popup-container')) {
      $(this).toggleClass('show');
    }
  });
});
